<template>
  <b-card>
    <b-row>
      <b-col v-for="target in targets" :key="`invitation-${target.tier}`">
        <b-button
          @click="(e) => loadTargetCriteria(target.tier)"
          class="btn-full-fill"
          >Load {{ target.tier }} Target Criteria</b-button
        >
      </b-col>
    </b-row>
    <div v-if="selectedTier">
      <hr />
      <b-row>
        <b-form class="full-fill-width" @reset="resetForm">
          <b-col>
            <b-form-group
              label="Gender"
              label-cols-sm="5"
              label-for="gender-form-filter"
            >
              <v-select
                id="gender-form-filter"
                v-model="filterForm.genders"
                multiple
                taggable
                :options="genderOptions"
                v-on:option:selected="(opt) => onSelected(opt, 'gender')"
              >
              </v-select>
            </b-form-group>
            <b-form-group
              label="Drinks"
              label-cols-sm="5"
              label-for="drinks-form-filter"
            >
              <v-select
                id="drinks-form-filter"
                v-model="filterForm.drinks"
                multiple
                taggable
                :options="drinkOptions"
                v-on:option:selected="(opt) => onSelected(opt, 'drink')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Brands"
              label-cols-sm="5"
              label-for="`brands-form-filter`"
            >
              <v-select
                id="brands-form-filter"
                v-model="filterForm.brands"
                multiple
                taggable
                :options="brandOptions"
                v-on:option:selected="(opt) => onSelected(opt, 'brand')"
              />
            </b-form-group>
            <b-form-group
              label="Age"
              label-cols-sm="5"
              label-for="age-form-start"
            >
              <b-form
                id="age-form-start"
                inline
                class="col-12 justify-content-center no-padding"
              >
                <b-form-input
                  class="col-12"
                  type="number"
                  min="20"
                  max="99"
                  step="1"
                ></b-form-input>
                <label class="col-12 text-center">To</label>
                <b-form-input
                  class="col-12"
                  type="number"
                  min="20"
                  max="99"
                  step="1"
                ></b-form-input>
              </b-form>
            </b-form-group>
          </b-col>
          <b-col class="padding-box">
            <b-form-checkbox-group
              v-model="filterForm.status"
              :options="statusOptions"
              name="invite-status-filter-form"
            ></b-form-checkbox-group>
          </b-col>
          <b-col>
            <b-row>
              <b-col xs="6">
                <b-button class="btn-full-fill" type="reset">DEFAULT</b-button>
              </b-col>
              <b-col xs="6">
                <b-button class="btn-full-fill">SUBMIT</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-form>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <b-table
            name="target-cirteria-table"
            id="target-cirteria-table"
            striped
            hover
            responsive
            :items="usersFiltered"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template #cell(sms)="data">
              <b-form-checkbox v-if="data.value !== ''" v-model="data.value">
              </b-form-checkbox>
              <div v-else></div>
            </template>
            <template #cell(email)="data">
              <b-form-checkbox v-if="data.value !== ''" v-model="data.value">
              </b-form-checkbox>
              <div v-else></div>
            </template>
            <template #cell(reserved)="data">
              <div v-if="data.value">
                <span>{{ data.value }}</span>
              </div>
              <div
                v-else-if="
                  data.item.status === 'Reserved' ||
                  data.item.status === 'Waiting List'
                "
              >
                <span>0</span>
              </div>
              <div v-else>
                <span>-</span>
              </div>
            </template>
          </b-table>
          <hr />
          <b-pagination
            name="target-cirteria-pagination"
            v-model="currentPage"
            :total-rows="usersFiltered.length"
            :per-page="perPage"
            aria-controls="target-cirteria-table"
            align="fill"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import VSelect from "vue-select";
import {
  onSelectedFormOption,
  getUsersTargetCriteria,
  getGenderOptions,
  getDrinkOptions,
  getBrandOptions,
  all_true,
  isin,
  arrIsin,
} from "@views/event-component/utils";

const statusOptions = ["Not Invited", "Invited", "Reserved", "Waiting List"];

function createFilter(target = {}) {
  return {
    genders: ["All"],
    drinks: ["All"],
    brands: ["All"],
    start_age: 0,
    end_age: 99,
    status: [...statusOptions],
    ...target,
  };
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
    VSelect,
  },
  name: "event-invitation",
  props: ["event", "targets"],
  watch: {
    event: {
      handler: function (val) {
        this.$emit("update:event", val);
      },
      deep: true,
    },
    targets: {
      handler: function (val) {
        this.$emit("update:targets", val);
      },
      deep: true,
    },
  },
  data() {
    return {
      users: [],
      selectedTier: null,
      fields: [
        { key: "rank", label: "Memdership Rank" },
        { key: "prefix_name", label: "" },
        { key: "first_name", label: "Name" },
        { key: "last_name", label: "surname" },
        { key: "gender", label: "Gender" },
        { key: "age", label: "Age" },
        { key: "drinks_str", label: "Drinks" },
        { key: "contact_methods_str", label: "Contact Method" },
        { key: "sms", label: "Send SMS" },
        { key: "email", label: "Send Email" },
        { key: "status", label: "Status" },
        { key: "reserved", label: "Amount" },
      ],
      filterForm: {},
      currentPage: 1,
      perPage: 5,
      genderOptions: [],
      drinkOptions: [],
      brandOptions: [],
      statusOptions: statusOptions.map((status) => {
        return { text: status, value: status };
      }),
    };
  },
  computed: {
    usersFiltered() {
      // return this.users
      return this.users.filter(this.filterUser);
    },
  },
  methods: {
    loadTargetCriteria(tier) {
      this.selectedTier = tier;
      this.filterForm = createFilter();
      getUsersTargetCriteria(tier).then((data) => {
        this.users = data;
      });
    },
    onSelected(options, refKey) {
      onSelectedFormOption(this, options, refKey);
    },
    filterUser(user) {
      let genderFilter = this.filterForm.genders.some(
        (e) => e === "All" || e === "all"
      )
        ? true
        : isin(this.filterForm.genders, user.gender);
      let drinkFilter = this.filterForm.drinks.some(
        (e) => e === "All" || e === "all"
      )
        ? true
        : arrIsin(this.filterForm.drinks, user.drinks);
      let brandFilter = this.filterForm.brands.some(
        (e) => e === "All" || e === "all"
      )
        ? true
        : arrIsin(this.filterForm.brands, user.brands);
      let statusFilter = isin(this.filterForm.status, user.status);
      let ageFilter =
        this.filterForm.start_age <= user.age &&
        user.age <= this.filterForm.end_age;
      return all_true([genderFilter, drinkFilter, brandFilter, statusFilter, ageFilter]);
    },
    resetForm() {
      this.filterForm = createFilter();
    },
  },
  mounted() {
    getGenderOptions().then((data) => {
      this.genderOptions = data;
    });
    getDrinkOptions().then((data) => {
      this.drinkOptions = data;
    });
    getBrandOptions().then((data) => {
      this.brandOptions = data;
    });
  },
};
</script>

<style scoped>
.btn-full-fill {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.full-fill-height {
  height: -webkit-fill-available;
}

.full-fill-width {
  width: -webkit-fill-available;
}

.padding-top {
  padding-top: 1rem;
}

.padding-box {
  padding: 1rem;
}
</style>
