<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <event-calendar
            :events="events"
            :eventClick="calendarEventClick"
            :dateClick="calendarDateClick"
            :selectedEvent="selectedEvent"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <event-upcoming :events="events" />
        </b-col>
      </b-row>
    </b-col>
    <b-col v-if="selectedEvent">
      <b-row>
        <b-col>
          <event-information :event="selectedEvent" :targets="targets" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <event-invitation :event="selectedEvent" :targets="targets" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BButton, BCol, BRow, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  EventInformation,
  EventInvitation,
} from "./";
import {
  EventCalendar,
  EventUpcoming,
} from "@views/event-component";
import { getEvents } from "@views/event-component/utils"

function createTargetCriteriaForm(tier){
    return {
        tier: tier,
        genders: [],
        drinks: [],
        brands: [],
        start_age: 0,
        end_age: 99,
    };
}

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    EventCalendar,
    EventInformation,
    EventInvitation,
    EventUpcoming,
  },
  directives: {
    Ripple,
  },
  watch: {
    selectedEvent: {
      handler: function (val) {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  mounted() {
    getEvents().then(data => {
      this.events = data
    })
  },
  data() {
    return {
      isVerticalMenuCollapsed:
        this.$store.state.verticalMenu.isVerticalMenuCollapsed,
      events: [],
      selectedEvent: null,
      targets: [createTargetCriteriaForm("1st"), createTargetCriteriaForm("2nd")],
    };
  },
  created() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },
  destroyed() {
    this.$store.commit(
      "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
      this.isVerticalMenuCollapsed
    );
  },
  methods: {
    calendarEventClick(event) {
      console.log(event, this.events);
      this.selectedEvent = this.events.find((e) => e.id.toString() === event.id);
    },
    calendarDateClick(date) {
      console.log(date);
    },
  },
};
</script>
