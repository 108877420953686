<template>
  <b-card title="Event Information">
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <b-row>
              <b-col class="col-4">
                {{ event.name || event.title }}
              </b-col>
              <b-col class="col-5">
                Target/Reserved: {{ event.target || "-" }} /
                {{ event.reserved || "-" }}
              </b-col>
              <b-col class="col-3">
                {{ dateStringToLocalize(event.date || event.start) }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-intent">
            <span>{{
              event.description || "No description this event for now"
            }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="event.image">
        <img :src="event.image" />
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col
        v-for="(target, i) in targets"
        :key="`criteria-tier-${target.tier}`"
      >
        <h4>{{ target.tier }} Target Criteria</h4>
        <b-form :key="`form-tier-${target.tier}`">
          <b-form-group
            label="Gender"
            label-cols-xl="5"
            :label-for="`gender-tier-${target.tier}`"
          >
            <v-select
              :id="`gender-tier-${target.tier}`"
              v-model="targets[i].genders"
              multiple
              taggable
              :options="genderOptions"
              v-on:option:selected="(opt) => onSelected(opt, target.tier, 'gender')"
            >
            </v-select>
          </b-form-group>
          <b-form-group
            label="Drinks"
            label-cols-xl="5"
            :label-for="`drinks-tier-${target.tier}`"
          >
            <v-select
              :id="`drinks-tier-${target.tier}`"
              v-model="targets[i].drinks"
              multiple
              taggable
              :options="drinkOptions"
              v-on:option:selected="(opt) => onSelected(opt, target.tier, 'drink')"
            />
          </b-form-group>
          <b-form-group
            label="Brands"
            label-cols-xl="5"
            :label-for="`brands-tier-${target.tier}`"
          >
            <v-select
              :id="`brands-tier-${target.tier}`"
              v-model="targets[i].brands"
              multiple
              taggable
              :options="brandOptions"
              v-on:option:selected="(opt) => onSelected(opt, target.tier, 'brand')"
            />
          </b-form-group>
          <b-form-group
            label="Age"
            label-cols-xl="5"
            :label-for="`age-tier-${target.tier}`"
          >
            <b-form
              :id="`age-tier-${target.tier}`"
              inline
              class="col-12 justify-content-center no-padding"
            >
              <b-form-input
                class="col-12"
                type="number"
                min="20"
                max="99"
                step="1"
              ></b-form-input>
              <label class="col-12 text-center">To</label>
              <b-form-input
                class="col-12"
                type="number"
                min="20"
                max="99"
                step="1"
              ></b-form-input>
            </b-form>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import VSelect from "vue-select";
import {
    onSelectedOption,
  getGenderOptions,
  getDrinkOptions,
  getBrandOptions,
} from "@views/event-component/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    VSelect,
    BFormInput,
  },
  name: "event-information",
  props: ["event", "targets"],
  data() {
    return {
      genderOptions: [],
      drinkOptions: [],
      brandOptions: [],
      ageOptions: [],
    };
  },
  methods: {
    dateToLocalize(date) {
      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
    dateStringToLocalize(dateStr) {
      return this.dateToLocalize(new Date(dateStr));
    },
    onSelected(options, tier, refKey) {
        onSelectedOption(this, options, tier, refKey)
    }
  },
  watch: {
    event: {
      handler: function (val) {
        this.$emit("update:event", val);
      },
      deep: true,
    },
    targets: {
      handler: function (val) {
        this.$emit("update:targets", this.targets);
        this.targets = val;
      },
      deep: true,
    },
  },
  mounted() {
    getGenderOptions().then((data) => {
      this.genderOptions = data;
    });
    getDrinkOptions().then((data) => {
      this.drinkOptions = data;
    });
    getBrandOptions().then((data) => {
      this.brandOptions = data;
    });
  },
};
</script>

<style scoped>
.text-intent {
  text-indent: 5%;
}

.no-padding {
  padding: 0;
}
</style>
